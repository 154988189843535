<template>
  <div class="questionnaire" v-loading="isLoading">
    <div class="create" @click="dialogCreate = true">
      <i></i><span>创建问卷</span>
    </div>
    <ul class="list clearfix">
      <li v-for="(item, index) in allData" :key="index">
        <div class="in">
          <div class="title">{{ item.title }}</div>
          <div class="info clearfix">
            <p>编号：{{ item.id }}</p>
            <p>答卷：<span class="blue">{{ item.num }}</span> 份</p>
            <p>状态：<span :class="[item.status === 2 ? 'blue' : item.status === 3 ? 'green' : 'red']">{{getStatus(item.status)}}</span></p>
            <p>创建：{{ item.create_time }}</p>
            <p>开始：{{ getTimeFromat(item.start_time) }}</p>
            <p>截止：{{ getTimeFromat(item.end_time) }}</p>
          </div>
          <div class="handle clearfix">
            <p @click="editClick(item)">
<!--              <router-link :to="{path: '/questionnaireEdit', query:{id : item.id}}"><span class="edit"><i></i>编辑</span></router-link>-->
              <span class="edit"><i></i>编辑</span>
            </p>
            <p @click="previewClick(item)">
              <span class="preview"><i></i>预览</span>
            </p>
            <p @click="deteleClick(item, index)">
              <span class="delete"><i></i>删除</span>
            </p>
            <p @click="dataClick(item)">
              <span class="data"><i></i>数据</span>
            </p>
          </div>
        </div>
      </li>
    </ul>
    <!-- 创建问卷 -->
	<add-popup :show="dialogCreate" title="创建问卷" :msg="{}" @close="closeClick" @change="createQuestion"></add-popup>


    <!-- 编辑提示 -->

    <el-dialog :title="editMsg ? '修改问卷' : ''" :visible.sync="editMsg" width="30%">
      <div style="text-align:left;padding: 0 20px;font-family: Microsoft YaHei;
font-weight: 400;
font-size: 12px;
color: #444F57;
line-height: 24px;">
        <p>调研进行中的问卷只能修改以下内容：<br /> 1.问卷基础信息：只可编辑结束时间；<br /> 2.问卷题目：不可编辑；<br /> 3.发送名单：可在原企业名单上新增企业。</p>
        </div>
        <div style="text-align:center;padding:20px;">
        <span style="display: inline-block;cursor: pointer; height: 40px;line-height: 40px;padding: 0 20px;border-radius: 4px;background-color: #3984F5;color: #ffffff;" @click="msgToEdit">已了解，进入编辑页面</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
	import {timeFormat} from "@/utils/index.js"
	import { Message } from "element-ui";
	import addPopup from "@/views/questionnaire/components/add-popup.vue"
export default {
	name: 'Questionnaire',
	components: {
		addPopup
	},
	data() {
		return {
			allData: [],//所有列表数据
			isLoading: false,//是否正在加载
			dialogCreate: false, // 创建问卷
			formCreate: { // 创建问卷表单
				name: '',
				startTime: '',
				endTime: ''
			},
      editMsg: false, // 编辑提示
      id: null, // 编辑提示
		}
	},
	mounted() {
		this.initData();
	},
	methods: {
	  initData() {
		  this.isLoading = true;
		  this.$api.questionnaire_list().then(res=>{
			  this.isLoading = false;
			  this.allData = res.data;
		  }).catch(err=>{
			  this.isLoading = false;
		  })
	  },
    dataClick(item){
      this.$router.push({path:'/questionnaireData',query:{id:item.id}})
    },
	  //创建问卷
	  createQuestion(e) {
		this.isLoading = true;
		this.dialogCreate = false;
		let data = {
			title: e.name,
			start_time: e.sTime,
			end_time: e.eTime
		}
    this.$api.questionnaire_save(data).then(res=>{
        this.isLoading = false;
        this.$message({message: '添加成功！', type: 'success'});
        this.$api.quest_list({ qid: res.data.id }).then(res=>{
        this.$store.commit('SET_QUESTION_LIST', res.data);
      })
        this.$router.push({path:'/questionnaireEdit',query:{id: res.data.id}})
      }).catch(err=>{
        this.isLoading = false;
      })
	  },
	  //删除问卷
	  deteleClick(e, index) {
		  if (e.status==2) return this.$message({type: 'warning', message: '当前问卷正在调研进行中，无法删除!'});
		   this.$confirm('是否确认删除?', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'}).then(() => {
				this.isLoading = true;
				this.$api.questionnaire_del(e.id).then(res=>{
					  this.isLoading = false;
					  this.$message({message: '删除成功！', type: 'success'});
					  this.allData.splice(index, 1);
				}).catch(err=>{
					this.isLoading = false;
					this.$message({message: '删除失败！', type: 'success'});
				})    
		    }).catch(err=>{})
	  },
	  //预览
	  previewClick(e) {
		  let pathInfo = this.$router.resolve({ path:'/preview',query:{ id: e.id }});
		   window.open(pathInfo.href, '_blank');
	  },//编辑
    editClick(e) {
      this.id = e.id;
      if (e.status === 3 ){
        this.$message({
          message: '当前问卷调研已结束，无法编辑！',
          type: 'warning'
        });
      }else if(e.status === 2){
        this.editMsg = true;
      }else {
        this.$api.quest_list({ qid: e.id }).then(res=>{
          this.$store.commit('SET_QUESTION_LIST', res.data);
        })
        this.$router.push({path:'/questionnaireEdit',query:{id: e.id}})
      }
	  },
    msgToEdit() {
      this.$api.quest_list({ qid: this.id }).then(res=>{
        this.$store.commit('SET_QUESTION_LIST', res.data);
      })
      this.$router.push({path:'/questionnaireEdit',query:{id: this.id}})
      this.editMsg = false;
    },
	  closeClick() {
		this.dialogCreate = false;  
	  },
	  //转化时间
	  getTimeFromat(time) {
		 let date =  timeFormat(time, 'yyyy-mm-dd hh:MM:ss');
		 return date;
	  },
	  //问卷状态获取
	  getStatus(num) {
		  let str = '草稿';
		  switch (num){
		  	case -1:
				str = '删除';
		  		break;
		  	case 0:
				str = '草稿';
		  		break;
		  	case 1:
				str = '未启动';
		  		break;
		  	case 2:
				str = '调研进行中';
		  		break;
		  	case 3:
				str = '调研结束';
		  		break;
		  	default:
		  		break;
		  }
		  return str;
	  }
  }
}
</script>

<style lang="scss" scoped>
.index-main {
  height: 100vh;
  .el-card__body, .el-main {
    padding: 0;
  }
}

.questionnaire {
  padding: 18px 0 0 18px;
  font-size: 12px;
  background-color: #f1f4f7;
  .create {
    margin-bottom: 18px;
    width: 104px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 4px;
    background-color: #3984f5;
    cursor: pointer;
    &:hover {
      background-color: #1C72F1;
    }
    i {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 2px;
      margin-right: 6px;
      width: 10px;
      height: 10px;
      background: url("~@/assets/questionnaire/survey_icon.png") no-repeat 0 0;
    }
    span {
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
    }
  }
  .list {
    li {
      float: left;
      margin-bottom: 18px;
      width: 50%;
    }
    .in {
      margin-right: 18px;
      background: #FFFFFF;
    }
    .title {
      padding: 0 24px;
      height: 64px;
      line-height: 64px;
      border-bottom: 1px solid #F1F4F7;
      font-size: 18px;
      color: #242D33;
    }
    .info {
      padding: 20px 10% 20px 24px;
      p {
        float: left;
        width: 33.3333%;
        color: #6C7880;
        line-height: 32px;
      }
      .blue {
        color: #3984F5;
      }
      .red {
        color: #FD5451;
      }
      .green {
        color: #04CD83;
      }
    }
    .handle {
      background: #F9FAFD;
      p {
        position: relative;
        float: left;
        width: 25%;
        text-align: center;
        line-height: 50px;
        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 13px;
          width: 1px;
          height: 24px;
          background-color: #E1E8EC;
        }
        &:last-child {
          &:after {
            width: 0;
          }
        }
      }
      span {
        color: #6C7880;
        &:hover {
			cursor: pointer;
          color: #3984F5;
          i {
            background-position-x: -20px;
          }
        }
      }
      i {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 2px;
        width: 20px;
        height: 20px;
        background: url("~@/assets/questionnaire/survey_icon.png") no-repeat 0 -10px;
      }
      .preview {
        i {
          background-position-y: -30px;
        }
      }
      .delete {
        i {
          background-position-y: -50px;
        }
      }
      .data {
        i {
          background-position-y: -70px;
        }
      }
    }
  }
}

::v-deep .dialog-create {
  border-radius: 4px;
  .el-dialog__header {
    padding: 10px 18px;
    border-bottom: 1px solid #eef2f5;
  }
  .el-dialog__title {
    line-height: 26px;
    font-size: 16px;
    color: #242d33;
  }
  .el-dialog__headerbtn {
    right: 16px;
    top: 11px;
    font-size: 22px;
  }
  .el-dialog__body {
    padding: 30px 40px;
  }
  .el-form-item {
    margin-bottom: 18px;
  }
  .el-form-item__label {
    font-size: 12px;
    color: #242d33;
    line-height: 34px;
  }
  .el-form-item__content {
    line-height: 34px;
  }
  .el-input__inner {
    height: 34px;
    line-height: 34px;
  }
}
</style>
