<template>
	<div class="">
		<el-dialog :title="title" :visible.sync="show" width="500px" custom-class="dialog-create" :before-close="close">
			<el-form >
				<el-form-item label="问卷名称:" label-width="70px">
					<el-input v-model="nameText" placeholder="输入问卷名称"></el-input>
				</el-form-item>
				<el-form-item label="开始时间:" label-width="70px">
					<el-date-picker v-model="startTime" type="datetime" placeholder="请选择开始时间" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间:" label-width="70px">
					<el-date-picker v-model="endTime" type="datetime" placeholder="请选择结束时间" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="createQuestion">保 存</el-button>
				<el-button @click="close">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		name: 'add-popup',
		props: {
			show: {
				type: Boolean,
				default: false
			},
			msg: {
				type: Object,
				default: {}
			},
			title: {
				type: String,
				default: ''
			}
		},
		data(){
			return{
				nameText: '',
				startTime: '',
				endTime: ''
			}
		},
		methods:{
			//创建
			createQuestion() {
				if (!this.nameText) return this.$message({message: '问卷名称不能为空！', type: 'error'});
				if (!this.startTime) return this.$message({message: '开始时间不能为空！', type: 'error'});
				if (!this.endTime) return this.$message({message: '结束时间不能为空！', type: 'error'});
				/*if (this.startTime.valueOf().toString().length > 10) {
					this.startTime = this.startTime.valueOf()/1000;
				}
				if (this.endTime.valueOf().toString().length > 10) {
					this.endTime = this.endTime.valueOf()/1000;
				}*/
        let cTime = this.getCurrentDateTime()
        let currentDate = new Date(cTime);
        currentDate.setHours(0, 0, 0, 0); // 将当前日期的时分秒部分设置为0

        let startTimeDate = new Date(this.startTime);
        startTimeDate.setHours(0, 0, 0, 0); // 将开始时间的时分秒部分设置为0
        if (this.startTime>this.endTime){
          this.$message.error('结束时间必须大于开始时间！');
        }else if (startTimeDate < currentDate){
          this.$message.error('开始时间不能小于当前日期！');
        }else if (this.endTime<cTime){
          this.$message.error('结束时间不能小于当前时间！');
        }else {
          this.$emit('change', {name: this.nameText, sTime: this.startTime, eTime: this.endTime});
        }
			},
      getCurrentDateTime() { //获取当前时间
        const currentDate = new Date();

        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');

        const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        return formattedDateTime;
      },
			//关闭
			close(done) {
				this.$emit('close');
			}
			
		},
		watch: {
			msg(newValue, value) {
				this.nameText = newValue.title;
				this.startTime = newValue.start_time;
				this.endTime = newValue.end_time;
			}
		}
	}
</script>

<style lang="scss" scoped>
	
	::v-deep .dialog-create {
	  border-radius: 4px;
	  .el-dialog__header {
	    padding: 10px 18px;
	    border-bottom: 1px solid #eef2f5;
	  }
	  .el-dialog__title {
	    line-height: 26px;
	    font-size: 16px;
	    color: #242d33;
	  }
	  .el-dialog__headerbtn {
	    right: 16px;
	    top: 11px;
	    font-size: 22px;
	  }
	  .el-dialog__body {
	    padding: 30px 40px;
	  }
	  .el-form-item {
	    margin-bottom: 18px;
	  }
	  .el-form-item__label {
	    font-size: 12px;
	    color: #242d33;
	    line-height: 34px;
	  }
	  .el-form-item__content {
	    line-height: 34px;
	  }
	  .el-input__inner {
	    height: 34px;
	    line-height: 34px;
	  }
	}
</style>